import { useEffect, useState } from 'react';
import DoughnutChart from '../../components/Charts/Doughnut';
import PageLayout from '../../components/PageLayout/PageLayout';
import {
  getSchoolSummary,
  getStudentsSummary,
  getTeachersSummary,
} from '../../redux/actions/summaryActions';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { baseURL } from '../../utils/axios';
import { useFetch } from '../../hooks/useFetch';

import './summary.scss';
import CustomLegend from '../../components/CustomLegend/CustomLegend';
import HorizontalBarChart from '../../components/Charts/HorizontalBarChart';
import {
  kwara_lga,
  total_female_students,
  total_male_students,
  total_students,
} from '../../data';

const Summary = () => {
  const dispatch = useDispatch();
  const [schoolState, setSchoolState] = useState('type');
  const [studentsState, setStudentsState] = useState('gender');

  useEffect(() => {
    dispatch(getSchoolSummary());
    dispatch(getStudentsSummary());
    dispatch(getTeachersSummary());
  }, []);

  const { schoolSummary, studentSummary, teachersSummary } = useSelector(
    (state) => state.summary
  );

  let schoolTypeData = [
    {
      label: 'Number of teachers',
      data: [teachersSummary?.maleTeachers, teachersSummary?.femaleTeachers],
      backgroundColor: ['#3E39DE', '#EC5EE3'],
      borderWidth: 1,
    },
  ];

  let schoolZoneData = [
    {
      label: 'Number of schools',
      data: [
        schoolSummary['Zone 1'],
        schoolSummary['Zone 2'],
        schoolSummary['Zone 3'],
        schoolSummary['Zone 4'],
        schoolSummary['Zone 5'],
      ],
      backgroundColor: ['#A5CF61', '#26A595', '#FF9E2C', '#F57070', '#F2CF8F'],
      borderWidth: 1,
    },
  ];

  let studentsGenderData = [
    {
      label: 'Number of students',
      // data: [studentSummary?.male, studentSummary?.female],
      data: [Number(total_male_students), Number(total_female_students)],
      backgroundColor: ['#3E39DE', '#EC5EE3'],
      borderWidth: 1,
    },
  ];

  let studentsZoneData = [
    {
      label: 'Number of students',
      data: [
        studentSummary['Zone 1'],
        studentSummary['Zone 2'],
        studentSummary['Zone 3'],
        studentSummary['Zone 4'],
        studentSummary['Zone 5'],
      ],
      backgroundColor: ['#A5CF61', '#26A595', '#FF9E2C', '#F57070', '#F2CF8F'],
      borderWidth: 1,
    },
  ];

  const labels = [
    'District I',
    'District II',
    'District III',
    'District IV',
    'District V',
    'District VI',
  ];

  const barData = {
    labels,
    datasets: [
      {
        label: 'enrolled',
        data: [800, 543, 654, 344, 945, 700],
        backgroundColor: '#4297DD',
        borderRadius: 5,
      },
    ],
  };

  let zonesUrl = `${baseURL}/states/9/zones`;
  const zones = useFetch(zonesUrl);

  let lgaUrl = `${baseURL}/states/9/local-government-areas`;
  const lga = useFetch(lgaUrl);

  const onChange = (value, type) => {
    if (type === 'students') {
      setStudentsState(value);
    } else {
      setSchoolState(value);
    }
  };

  const onSearchChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  return (
    <PageLayout currentPage={'Summary'}>
      <div className='ato-management__select-section'>
        <Select
          defaultValue='All Zones'
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       e,
          //       '',
          //       '',
          //       ''
          //     )
          //   );
          // }}
          options={zones?.data?.data?.state?.zones.map((el) => ({
            value: el?.id,
            label: el?.title,
          }))}
        />

        <Select
          showSearch
          defaultValue={`All LGAs`}
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       '',
          //       e,
          //       '',
          //       ''
          //     )
          //   );
          // }}
          onSearch={onSearch}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={kwara_lga.map((el) => ({
            value: el?.id,
            label: el?.name,
          }))}
        />

        <Select
          defaultValue='All School Types(2)'
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       '',
          //       '',
          //       e,
          //       ''
          //     )
          //   );
          // }}
          options={[
            {
              value: 1,
              label: 'Private School',
            },
            {
              value: 2,
              label: 'Public School',
            },
          ]}
        />

        <Select
          defaultValue='All School levels (3)'
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       '',
          //       '',
          //       '',
          //       e
          //     )
          //   );
          // }}
          options={[
            {
              value: 1,
              label: 'Nursery',
            },
            {
              value: 2,
              label: 'Primary',
            },
            {
              value: 3,
              label: 'Secondary',
            },
          ]}
        />
      </div>
      <h3 className='page-title'>Summary</h3>
      <div className='summary'>
        <div className='summary-card'>
          <div className='summary-card__heading'>
            <h3 className='summary-card__title'>Number of Students</h3>
          </div>

          <div className='summary-card__chart'>
            <div className='summary-card__chart__item'>
              <DoughnutChart
                datasets={
                  studentsState === 'gender'
                    ? studentsGenderData
                    : studentsZoneData
                }
                labels={
                  studentsState === 'gender'
                    ? ['Male', 'Female']
                    : ['Zone 1', 'Zone 2', 'Zone 3', 'Zone 4', 'Zone 5']
                }
              />
            </div>
            <div className='summary-card__chart__details'>
              <p>Total number of students</p>
              <h3>{total_students.toLocaleString()}</h3>
              {/* <h3>{studentSummary?.total}</h3> */}
            </div>
          </div>
          <CustomLegend
            data={[
              { id: 1, label: 'Female', bgColor: '#EC5EE3' },
              { id: 2, label: 'Male', bgColor: '#3E39DE' },
            ]}
          />
        </div>

        <div className='summary-card'>
          <div className='summary-card__heading'>
            <h3 className='summary-card__title'>Number of Teachers</h3>
          </div>

          <div className='summary-card__chart'>
            <div className='summary-card__chart__item'>
              <DoughnutChart
                datasets={
                  schoolState === 'zone' ? schoolZoneData : schoolTypeData
                }
                labels={['Male', 'Female']}
              />
            </div>

            <div className='summary-card__chart__details'>
              <p>Total number of teachers</p>
              {/* <h3>{teachersSummary?.totalTeachers}</h3> */}
              <h3>279</h3>
            </div>
          </div>
          <CustomLegend
            data={[
              { id: 1, label: 'Female', bgColor: '#EC5EE3' },
              { id: 2, label: 'Male', bgColor: '#3E39DE' },
            ]}
          />
        </div>

        {/* <div className='summary-card'>
          <h3 className='summary-card__title'>Number of Teachers</h3>

          <div className='summary-card__chart'>
            <div className='summary-card__chart__item'>
              <DoughnutChart
                datasets={schoolData}
                labels={['Private', 'Public']}
              />
            </div>
            <div className='summary-card__chart__details'>
              <p>Total Teachers</p>
              <h3>300</h3>
            </div>
          </div>
        </div> */}
      </div>

      <div className='summary-card-full'>
        <div className='summary-card-full__title'>
          <h3>Student enrolment summary</h3>
          <select>
            <option>Q1 2020</option>
          </select>
        </div>
        <div className='summary-card-full__chart-wrap'>
          <div className='summary-card-full__chart-wrap__chart'>
            <HorizontalBarChart data={barData} />
          </div>

          <div className='summary-card-full__chart-wrap__details'>
            <div className='summary-card-full__chart-wrap__details__item'>
              <p>Total number of enroled students</p>
              <h3>{total_students.toLocaleString()}</h3>
            </div>

            <div className='summary-card-full__chart-wrap__details__item'>
              <p>Enrolled male students</p>
              <h3>{total_male_students.toLocaleString()}</h3>
            </div>

            <div className='summary-card-full__chart-wrap__details__item'>
              <p>Enrolled female s tudents</p>
              <h3>{total_female_students.toLocaleString()}</h3>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Summary;
