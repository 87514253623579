import { useEffect, useState } from 'react';
import DoughnutChart from '../../components/Charts/Doughnut';
import PageLayout from '../../components/PageLayout/PageLayout';
import { getAttendanceAnalytics } from '../../redux/actions/attendanceActions';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { baseURL } from '../../utils/axios';
import { useFetch } from '../../hooks/useFetch';
import { Spin } from 'antd';

import './attendance.scss';
import CustomLegend from '../../components/CustomLegend/CustomLegend';
import HorizontalBarChart from '../../components/Charts/HorizontalBarChart';
import { kwara_lga, total_students } from '../../data';

const Attendance = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAttendanceAnalytics());
  }, []);

  const { attendanceAnalytics, loading } = useSelector(
    (state) => state.attendance
  );

  // let attendaceData = [
  //   {
  //     label: 'Attendance',
  //     data: [
  //       attendanceAnalytics?.present?.total,
  //       attendanceAnalytics?.late?.total,
  //       attendanceAnalytics?.absent?.total,
  //     ],
  //     backgroundColor: ['#4BC801', '#FFE711', '#E78687'],
  //     borderWidth: 1,
  //   },
  // ];
  let attendaceData = [
    {
      label: 'Attendance',
      data: [2984562, 20121, 4300],
      backgroundColor: ['#4BC801', '#FFE711', '#E78687'],
      borderWidth: 1,
    },
  ];
  const labels = ['Zone 1', 'Zone 2', 'Zone 3', 'Zone 4', 'Zone 5'];

  // const barData = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'enrolled',
  //       data: [
  //         attendanceAnalytics['Zone 1'],
  //         attendanceAnalytics['Zone 2'],
  //         attendanceAnalytics['Zone 3'],
  //         attendanceAnalytics['Zone 4'],
  //         attendanceAnalytics['Zone 5'],
  //       ],
  //       backgroundColor: '#4297DD',
  //       borderRadius: 5,
  //     },
  //   ],
  // };

  const barData = {
    labels,
    datasets: [
      {
        label: 'enrolled',
        data: [130, 132, 152, 124, 164],
        backgroundColor: '#4297DD',
        borderRadius: 5,
      },
    ],
  };

  let zonesUrl = `${baseURL}/states/9/zones`;
  const zones = useFetch(zonesUrl);

  let lgaUrl = `${baseURL}/states/9/local-government-areas`;
  const lga = useFetch(lgaUrl);

  const onSearch = (value) => {
    console.log('search:', value);
  };

  return (
    <PageLayout currentPage={'Attendance'}>
      <div className='ato-management__select-section'>
        <Select
          defaultValue='All Zones'
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       e,
          //       '',
          //       '',
          //       ''
          //     )
          //   );
          // }}
          options={zones?.data?.data?.state?.zones.map((el) => ({
            value: el?.id,
            label: el?.title,
          }))}
        />

        <Select
          showSearch
          defaultValue={`All LGAs`}
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       '',
          //       e,
          //       '',
          //       ''
          //     )
          //   );
          // }}
          onSearch={onSearch}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={kwara_lga.map((el) => ({
            value: el?.id,
            label: el?.name,
          }))}
        />

        <Select
          defaultValue='All School Types(2)'
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       '',
          //       '',
          //       e,
          //       ''
          //     )
          //   );
          // }}
          options={[
            {
              value: 1,
              label: 'Private School',
            },
            {
              value: 2,
              label: 'Public School',
            },
          ]}
        />

        <Select
          defaultValue='All School levels (3)'
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       '',
          //       '',
          //       '',
          //       e
          //     )
          //   );
          // }}
          options={[
            {
              value: 1,
              label: 'Nursery',
            },
            {
              value: 2,
              label: 'Primary',
            },
            {
              value: 3,
              label: 'Secondary',
            },
          ]}
        />
      </div>
      <h3 className='page-title'>Attendance</h3>
      <div className='summary'>
        <div className='summary-card'>
          <div className='summary-card__heading'>
            <h3 className='summary-card__title'>Stats</h3>

            <div className='summary-card__heading__flex'>
              <select>
                <option>2022/2023 Session</option>
              </select>
              <select>
                <option>All terms</option>
              </select>
            </div>
          </div>

          <div className='summary-card__chart'>
            {loading ? (
              <Spin />
            ) : (
              <>
                <div className='summary-card__chart__item'>
                  <DoughnutChart
                    datasets={attendaceData}
                    labels={['Present', 'Late', 'Absent']}
                  />
                </div>
                <div className='summary-card__chart__details'>
                  <p>Total number of Students</p>
                  {/* <h3>{attendanceAnalytics?.students}</h3> */}
                  <h3>{total_students.toLocaleString()}</h3>
                </div>
              </>
            )}
          </div>
          <CustomLegend
            data={[
              { id: 1, label: 'Present', bgColor: '#4BC801' },
              { id: 2, label: 'Late', bgColor: '#FFE711' },
              { id: 3, label: 'Absent', bgColor: '#E78687' },
            ]}
          />
        </div>

        <div className='summary-card'>
          <div className='summary-card__heading'>
            <h3 className='summary-card__title'>Attendance by zone</h3>
            <div className='summary-card__heading__flex'>
              <select>
                <option>2022/2023 Session</option>
              </select>
            </div>
          </div>

          <div className='summary-card__chart'>
            <div
              className='summary-card__chart__item'
              style={{ width: '100%' }}
            >
              <HorizontalBarChart data={barData} />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Attendance;
