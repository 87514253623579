import React from 'react';
import logo from '../../assets/kwara.png';
import './layout.scss';

import { Breadcrumb, Layout, Menu } from 'antd';
import Textsnippet from '../../icons/Textsnippet';
import Summary from '../../icons/Summary';
import { NavLink } from 'react-router-dom';
const { Header, Content, Sider } = Layout;

const PageLayout = ({ children, currentPage }) => (
  <Layout>
    <Header className='header'>
      <div className='navbar'>
        <img src={logo} alt='' />
        <p>Kwara State</p>
      </div>

      <div className='navbar-middle'>
        <p>Kwara State Ministry of Education Dashboard </p>
      </div>
      <div style={{ opacity: 0 }}>
        <input placeholder='Search' className='navbar-search' />
      </div>
    </Header>
    <Layout>
      <Sider
        width={224}
        breakpoint='lg'
        collapsedWidth='0'
        onBreakpoint={(broken) => {}}
        onCollapse={(collapsed, type) => {}}
        className='site-layout-background'
      >
        <div className='sidebar-menu'>
          {/* <Menu
            mode='inline'
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{
              height: '100%',
              borderRight: 0,
            }}
            items={items1}
          /> */}

          {/* <Menu
            mode='inline'
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{
              height: '100%',
              borderRight: 0,
            }}
            items={}
          /> */}
          <NavLink
            className='sidebar-menu__items'
            to='/summary'
            activeClassName='active-nav'
          >
            <Summary />
            <p>Summary</p>
          </NavLink>
          <NavLink
            className='sidebar-menu__items'
            to='/school-records'
            activeClassName='active-nav'
          >
            <Summary />
            <p>School Records</p>
          </NavLink>
          <NavLink
            className='sidebar-menu__items'
            to='/attendance'
            activeClassName='active-nav'
          >
            <Summary />
            <p>Attendance</p>
          </NavLink>

          <NavLink
            className='sidebar-menu__items'
            to='/students-results'
            activeClassName='active-nav'
          >
            <Summary />
            <p>Students Results</p>
          </NavLink>
          <NavLink
            className='sidebar-menu__items'
            to='/ato-management'
            activeClassName='active-nav'
          >
            <Textsnippet />
            <p>ATO Management</p>
          </NavLink>

          <NavLink
            className='sidebar-menu__items'
            to='/approvals'
            activeClassName='active-nav'
          >
            <Textsnippet />
            <p>Approvals</p>
          </NavLink>
        </div>
      </Sider>
      <Layout
        style={{
          padding: '110px 24px 24px 24px',
        }}
      >
        {/* <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>{currentPage}</Breadcrumb.Item>
        </Breadcrumb> */}
        <Content className='site-layout-background'>{children}</Content>
      </Layout>
    </Layout>
  </Layout>
);
export default PageLayout;
