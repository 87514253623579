import React, { useState, useEffect } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import { Helmet } from 'react-helmet';
import './ato.scss';
import { Select, Space, Popover, Spin, Modal, Pagination } from 'antd';
import Table from '../../components/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAtoRequests,
  filterApprovals,
  getApprovals,
} from '../../redux/actions/atoActions';
import View from '../../icons/View';
import More from '../../icons/More';
import Done from '../../icons/Done';
import Reject from '../../icons/Reject';
import {
  approveAtoRequest,
  rejectAtoRequest,
  revokeAtoRequest,
} from '../../services/atoService';
import toast from 'react-hot-toast';
import Revoke from '../../icons/Revoke';
import TextInput from '../../components/Input/TextInput';
import { baseURL } from '../../utils/axios';
import { useFetch } from '../../hooks/useFetch';
import Clear from '../../icons/Clear';
import SingleSchool from '../../components/SingleSchool/SingleSchool';
import { kwara_lga } from '../../data';

const Approvals = () => {
  const dispatch = useDispatch();
  const { loading, approvals } = useSelector((state) => state.ato);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSingleSchool, setShowSingleSchool] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [atoId, setAtoId] = useState('');
  const [filteredSchool, setFilteredSchool] = useState([]);
  const [activeFilter, setActiveFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);

  let actionsUrl = `${baseURL}/actions`;
  const { data } = useFetch(actionsUrl);

  let zonesUrl = `${baseURL}/states/9/zones`;
  const zones = useFetch(zonesUrl);

  let lgaUrl = `${baseURL}/states/9/local-government-areas`;
  const lga = useFetch(lgaUrl);

  useEffect(() => {
    dispatch(getApprovals('', 1));
  }, []);

  const [state, setState] = useState({
    action: '',
    rejectionReason: '',
  });

  const [errors, setErrors] = useState({
    action: '',
    rejectionReason: '',
  });

  const handleChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const selectValue = (option, name) => {
    setState({ ...state, [name]: option });
  };

  const handleBlur = (evt) => {
    const value = evt.target.value;
    if (!value) {
      setErrors({
        ...errors,
        [evt.target.name]: `Field cannot be empty`,
      });
    } else {
      setErrors({
        ...errors,
        [evt.target.name]: ``,
      });
    }
  };

  //modal actions
  const showModal = (id) => {
    setIsModalOpen(true);
    setAtoId(id);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setShowSingleSchool(false);
  };

  const takeAction = async (type, id) => {
    try {
      if (type === 'approve') {
        setActionLoading(true);
        let res = await approveAtoRequest(id);

        toast.success('ATO approved successfully');
        setActionLoading(false);
        dispatch(getApprovals('', 1));
      }

      if (type === 'reject') {
        setRejectLoading(true);
        let actionId = localStorage.getItem('actionId');
        let data = {
          action: actionId,
          rejectionReason: state.rejectionReason,
        };
        let res = await rejectAtoRequest(atoId, data);
        toast.success('ATO rejected successfully');
        handleCancel();
        setState({
          action: '',
          rejectionReason: '',
        });
        setRejectLoading(false);
        dispatch(getApprovals('', 1));
      }

      if (type === 'revoke') {
        setActionLoading(true);
        let res = await revokeAtoRequest(id);
        toast.success('ATO revoked successfully');
        setActionLoading(false);
        dispatch(getApprovals('', 1));
      }
      setActionLoading(false);
    } catch (error) {
      setActionLoading(false);
      setRejectLoading(false);
      toast.error(`Unable to ${type}, please try again`);
    }
  };
  const columns = [
    {
      title: 'School Name',
      dataIndex: 'school',
      render: (_, { school }) => (
        <>
          <p>{school?.name}</p>
        </>
      ),
    },
    {
      title: 'SVC',
      dataIndex: 'code',
      render: (_, { code }) => (
        <>
          <p>{code ? code : 'Not Available'}</p>
        </>
      ),
    },

    {
      title: 'Type',
      dataIndex: 'approval',
      render: (_, el) => (
        <>
          <p>{el?.payment?.id ? 'Paid for ATO' : 'Uploaded ATO'}</p>
        </>
      ),
    },
    {
      title: 'ATO Status',
      dataIndex: 'status',
      render: (_, { revoked }) => (
        <>
          <div className={`badge ${revoked === true ? 'revoked' : 'approved'}`}>
            {revoked === true ? 'revoked' : 'approved'}
          </div>
        </>
      ),
    },

    {
      title: 'Actions',
      dataIndex: 'id',
      render: (_, record) => (
        <Space size='middle'>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => showSchool(record?.id)}
          >
            <View />
          </div>
          {/* <Popover
            placement='topLeft'
            content={<Actions value={record} />}
            trigger='click'
          >
            <div style={{ cursor: 'pointer' }}>
              <More />
            </div>
          </Popover> */}
        </Space>
      ),
    },
  ];

  const showSchool = (id) => {
    setShowSingleSchool(true);
    setFilteredSchool(approvals?.data?.filter((el) => el.id === id));
  };

  const Actions = (value) => {
    return (
      <div className='actions-list'>
        <div
          className='actions-list__single'
          onClick={() => showSchool(value?.value?.id)}
        >
          <View />
          <p>View School</p>
        </div>

        {value.value.revoked === false && (
          <div
            className='actions-list__single'
            onClick={() => takeAction('revoke', value?.value?.id)}
          >
            <Done />
            <p>Revoke ATO</p>
          </div>
        )}
      </div>
    );
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  //paginate
  const paginate = (e) => {
    dispatch(getAtoRequests('', e));
    setCurrentPage(e);
  };

  return (
    <>
      {actionLoading ? (
        <Spin>
          <PageLayout currentPage={'Approvals'}>
            <Helmet>
              <title>ATO Management</title>
            </Helmet>
            <div className='ato-management'>
              <div className='ato-management__select-section'>
                <Select
                  showSearch
                  defaultValue='All Zones (5)'
                  style={{
                    width: 120,
                  }}
                  optionFilterProp='children'
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={zones.data.data.state.zones.map((el) => ({
                    value: el.id,
                    label: el.title,
                  }))}
                />

                <Select
                  showSearch
                  defaultValue='All LGAs (5)'
                  style={{
                    width: 120,
                  }}
                  optionFilterProp='children'
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'jack',
                      label: 'Jack',
                    },
                    {
                      value: 'lucy',
                      label: 'Lucy',
                    },
                    {
                      value: 'disabled',
                      disabled: true,
                      label: 'Disabled',
                    },
                    {
                      value: 'Yiminghe',
                      label: 'yiminghe',
                    },
                  ]}
                />

                <Select
                  showSearch
                  defaultValue='All School Types (5)'
                  style={{
                    width: 120,
                  }}
                  optionFilterProp='children'
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'jack',
                      label: 'Jack',
                    },
                    {
                      value: 'lucy',
                      label: 'Lucy',
                    },
                    {
                      value: 'disabled',
                      disabled: true,
                      label: 'Disabled',
                    },
                    {
                      value: 'Yiminghe',
                      label: 'yiminghe',
                    },
                  ]}
                />

                <Select
                  showSearch
                  defaultValue='All School levels (5)'
                  style={{
                    width: 120,
                  }}
                  optionFilterProp='children'
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'jack',
                      label: 'Jack',
                    },
                    {
                      value: 'lucy',
                      label: 'Lucy',
                    },
                    {
                      value: 'disabled',
                      disabled: true,
                      label: 'Disabled',
                    },
                    {
                      value: 'Yiminghe',
                      label: 'yiminghe',
                    },
                  ]}
                />
              </div>

              <div className='ato-management__table'>
                <div className='ato-management__table__title'>
                  <h4>ATO Management</h4>
                </div>
                <div className='ato-management__table__card'>
                  <div className='ato-management__table__card__filters'>
                    <div
                      className={`ato-management__table__card__filters__filter ${
                        activeFilter === 'approved' && 'active-filter'
                      }`}
                      onClick={() => {
                        setActiveFilter('approved');
                        dispatch(getAtoRequests('approved', 1));
                      }}
                    >
                      <p>Approved</p>
                      {/* <div className='ato-management__table__card__filters__filter-badge'>
                        {}
                      </div> */}
                    </div>

                    <div
                      className={`ato-management__table__card__filters__filter ${
                        activeFilter === 'pending' && 'active-filter'
                      }`}
                      onClick={() => {
                        setActiveFilter('pending');
                        dispatch(getAtoRequests('pending', 1));
                      }}
                    >
                      <p>Pending</p>
                      {/* <div className='ato-management__table__card__filters__filter-badge'>
                        2
                      </div> */}
                    </div>

                    <div
                      className={`ato-management__table__card__filters__filter ${
                        activeFilter === 'rejected' && 'active-filter'
                      }`}
                      onClick={() => {
                        setActiveFilter('rejected');
                        dispatch(getAtoRequests('rejected', 1));
                      }}
                    >
                      <p>Rejected</p>
                      {/* <div className='ato-management__table__card__filters__filter-badge'>
                        2
                      </div> */}
                    </div>
                  </div>
                  <Table
                    data={approvals?.data ? Object.values(approvals?.data) : []}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </PageLayout>
        </Spin>
      ) : (
        <PageLayout currentPage={'ATO Management'}>
          <Helmet>
            <title>ATO Management</title>
          </Helmet>
          <div className='ato-management'>
            <div className='ato-management__select-section'>
              <Select
                defaultValue='All Zones'
                style={{
                  width: 120,
                }}
                optionFilterProp='children'
                onChange={(e) => {
                  dispatch(
                    filterApprovals(
                      activeFilter !== 'all' ? activeFilter : '',
                      currentPage,
                      e,
                      '',
                      '',
                      ''
                    )
                  );
                }}
                options={zones?.data?.data?.state?.zones.map((el) => ({
                  value: el?.id,
                  label: el?.title,
                }))}
              />

              <Select
                showSearch
                defaultValue={`All LGAs`}
                style={{
                  width: 120,
                }}
                optionFilterProp='children'
                onChange={(e) => {
                  dispatch(
                    filterApprovals(
                      activeFilter !== 'all' ? activeFilter : '',
                      currentPage,
                      '',
                      '',
                      e,
                      ''
                    )
                  );
                }}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={kwara_lga.map((el) => ({
                  value: el?.id,
                  label: el?.name,
                }))}
              />

              <Select
                defaultValue='All School Types(2)'
                style={{
                  width: 120,
                }}
                optionFilterProp='children'
                onChange={(e) => {
                  dispatch(
                    filterApprovals(
                      activeFilter !== 'all' ? activeFilter : '',
                      currentPage,
                      '',
                      e,
                      '',
                      ''
                    )
                  );
                }}
                options={[
                  {
                    value: 1,
                    label: 'Private School',
                  },
                  {
                    value: 2,
                    label: 'Public School',
                  },
                ]}
              />

              <Select
                defaultValue='All School levels (3)'
                style={{
                  width: 120,
                }}
                optionFilterProp='children'
                onChange={(e) => {
                  dispatch(
                    filterApprovals(
                      activeFilter !== 'all' ? activeFilter : '',
                      currentPage,
                      '',
                      '',
                      '',
                      e
                    )
                  );
                }}
                options={[
                  {
                    value: 1,
                    label: 'Nursery',
                  },
                  {
                    value: 2,
                    label: 'Primary',
                  },
                  {
                    value: 3,
                    label: 'Secondary',
                  },
                ]}
              />
            </div>

            <div className='ato-management__table'>
              <div className='ato-management__table__title'>
                <h4>ATO Management</h4>
              </div>
              <div className='ato-management__table__card'>
                <div className='ato-management__table__card__filters'>
                  <div
                    className={`ato-management__table__card__filters__filter ${
                      activeFilter === 'all' && 'active-filter'
                    }`}
                    onClick={() => {
                      dispatch(getApprovals('', 1));
                    }}
                  >
                    <p>All</p>
                    {/* <div className='ato-management__table__card__filters__filter-badge'>
                      2
                    </div> */}
                  </div>

                  <div
                    className={`ato-management__table__card__filters__filter ${
                      activeFilter === 'revoked' && 'active-filter'
                    }`}
                    onClick={() => {
                      setActiveFilter('revoked');
                      dispatch(getApprovals(`&revoked=true`, 1));
                    }}
                  >
                    <p>Revoked</p>
                    {/* <div className='ato-management__table__card__filters__filter-badge'>
                      2
                    </div> */}
                  </div>

                  <div
                    className={`ato-management__table__card__filters__filter ${
                      activeFilter === 'unrevoked' && 'active-filter'
                    }`}
                    onClick={() => {
                      setActiveFilter('unrevoked');
                      dispatch(getApprovals(`&revoked=false`, 1));
                    }}
                  >
                    <p>Unrevoked</p>
                    {/* <div className='ato-management__table__card__filters__filter-badge'>
                      2
                    </div> */}
                  </div>
                </div>
                {loading ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '50px',
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <>
                    <Table
                      data={
                        approvals?.data ? Object.values(approvals?.data) : []
                      }
                      columns={columns}
                    />
                    <div style={{ marginTop: '24px' }}>
                      <Pagination
                        defaultCurrent={1}
                        total={approvals?.total}
                        onChange={paginate}
                        current={currentPage}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <Modal
            open={isModalOpen}
            onOk={handleCancel}
            onCancel={handleCancel}
            footer={null}
          >
            <div className='reject-modal'>
              <div className='reject-modal__heading'>
                <h2>Reject ATO Status</h2>
                <p>
                  Select the ATO status and date of approval/ rejection of the
                  document
                </p>
              </div>
              <div className='reject-modal__form'>
                <TextInput
                  label='Approval Status'
                  fieldClass={
                    errors['username'] ? 'error-field' : 'input-field'
                  }
                  value={'Reject'}
                  type='text'
                  errorMessage={errors['username']}
                  errorClass={'error-msg'}
                  disabled
                />

                <TextInput
                  label='Reason Type*'
                  placeholder='Select the type of reason'
                  name='action'
                  value={state.action}
                  onSelectValue={selectValue}
                  type='dropdown'
                  options={data?.data?.actions}
                  errorClass={'error-msg'}
                />

                <TextInput
                  label='Reason for Rejection'
                  fieldClass={
                    errors['rejectionReason'] ? 'error-field' : 'input-field'
                  }
                  placeholder='Explain in details the reason for the rejection'
                  name='rejectionReason'
                  value={state.rejectionReason}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type='textarea'
                  errorMessage={errors['rejectionReason']}
                  errorClass={'error-msg'}
                />

                <button
                  className='reject-button'
                  onClick={() => takeAction('reject', atoId)}
                  disabled={
                    rejectLoading ||
                    state.rejectionReason === '' ||
                    state.action === ''
                  }
                >
                  <div className='btn-flex'>
                    <Clear />
                    {rejectLoading ? 'Please wait...' : 'Reject ATO'}
                  </div>
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            open={showSingleSchool}
            onOk={handleCancel}
            onCancel={handleCancel}
            footer={null}
          >
            <SingleSchool
              school={filteredSchool}
              takeAction={takeAction}
              showModal={showModal}
              setShowSingleSchool={setShowSingleSchool}
            />
          </Modal>
        </PageLayout>
      )}
    </>
  );
};

export default Approvals;
